import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3bdf18ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-col-12 p-md-6 p-inputgroup" }
const _hoisted_3 = { class: "p-col-12 p-md-6 p-inputgroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_CreateCustomer = _resolveComponent("CreateCustomer")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Paginator = _resolveComponent("Paginator")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.customers.items,
    paginator: false,
    class: "p-datatable-customers",
    dataKey: "id",
    rowHover: true,
    rows: _ctx.customers.meta.itemCount,
    selection: _ctx.modelValue,
    "onUpdate:selection": _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    onRowClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('update:modelValue', $event.data))),
    filterDisplay: "menu",
    globalFilterFields: ['name', 'phone', 'email'],
    "auto-layout": ""
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            type: "button",
            icon: "pi pi-filter-slash",
            label: _ctx.$t('clear'),
            class: "p-button-outlined p-mb-1",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearFilter())),
            loading: _ctx.loading,
            style: {"margin":"5px"}
          }, null, 8, ["label", "loading"]),
          _createVNode(_component_CreateCustomer, { clas: "p-button-sm p-button-outlined" })
        ]),
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_InputText, {
            modelValue: _ctx.filters.global.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.global.value) = $event)),
            placeholder: _ctx.$t('search'),
            onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.submitSearch('global')), ["enter"]))
          }, null, 8, ["modelValue", "placeholder"]),
          _createVNode(_component_Button, {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submitSearch('global'))),
            type: "submit",
            label: _ctx.$t('search'),
            icon: "pi pi-search"
          }, null, 8, ["label"])
        ])
      ])
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("noData")), 1)
    ]),
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("customer.dataTableLoading")), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Paginator, {
        template: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
        "current-page-report-template": (_ctx.$tm('currentPageReportTemplate') as any),
        rows: _ctx.input.limit,
        "onUpdate:rows": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.input.limit) = $event)),
        onPage: _cache[5] || (_cache[5] = (evt) => (_ctx.input.page = evt.page + 1)),
        totalRecords: _ctx.customers.meta.totalItems,
        rowsPerPageOptions: [10, 20, 40]
      }, null, 8, ["current-page-report-template", "rows", "totalRecords"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        selectionMode: (_ctx.selectionMode as any),
        headerStyle: "width: 3rem"
      }, null, 8, ["selectionMode"]),
      _createVNode(_component_Column, {
        "show-add-button": false,
        showFilterOperator: false,
        field: "name",
        header: _ctx.$t('customer.name'),
        sortable: true
      }, {
        filter: _withCtx(({ filterModel, filterCallback }) => [
          _createVNode(_component_InputText, {
            type: "text",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            onKeyup: ($event: any) => (filterCallback()),
            class: "p-column-filter",
            placeholder: _ctx.$t('customer.filterByName')
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup", "placeholder"])
        ]),
        filterclear: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            icon: "pi pi-times",
            onClick: ($event: any) => (filterCallback()),
            class: "p-button-warning",
            label: _ctx.$t('clear_')
          }, null, 8, ["onClick", "label"])
        ]),
        filterapply: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            icon: "pi pi-search",
            label: _ctx.$t('search'),
            class: "p-button-success",
            onClick: ($event: any) => (_ctx.submitSearch('name', filterCallback))
          }, null, 8, ["label", "onClick"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "phone",
        header: _ctx.$t('customer.phone'),
        "show-add-button": false,
        showFilterOperator: false,
        filterField: "phone",
        sortable: true,
        "sort-field": "phone"
      }, {
        filter: _withCtx(({ filterModel, filterCallback }) => [
          _createVNode(_component_InputText, {
            type: "text",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            class: "p-column-filter",
            onKeyup: ($event: any) => (filterCallback()),
            placeholder: _ctx.$t('customer.filterByPhone')
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup", "placeholder"])
        ]),
        filterclear: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            onClick: ($event: any) => (filterCallback()),
            label: _ctx.$t('clear_'),
            class: "p-button-warning"
          }, null, 8, ["onClick", "label"])
        ]),
        filterapply: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('search'),
            onClick: ($event: any) => (_ctx.submitSearch('phone', filterCallback)),
            class: "p-button-success"
          }, null, 8, ["label", "onClick"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "email",
        header: _ctx.$t('customer.email'),
        "show-add-button": false,
        showFilterOperator: false,
        filterField: "email",
        sortable: true,
        "sort-field": "email"
      }, {
        filter: _withCtx(({ filterModel, filterCallback }) => [
          _createVNode(_component_InputText, {
            type: "text",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            class: "p-column-filter",
            onKeyup: ($event: any) => (filterCallback()),
            placeholder: _ctx.$t('customer.filterByEmail')
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup", "placeholder"])
        ]),
        filterclear: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            onClick: ($event: any) => (filterCallback()),
            label: _ctx.$t('clear_'),
            class: "p-button-warning"
          }, null, 8, ["onClick", "label"])
        ]),
        filterapply: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.$t('search'),
            onClick: ($event: any) => (_ctx.submitSearch('email', filterCallback)),
            class: "p-button-success"
          }, null, 8, ["label", "onClick"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        "show-add-button": false,
        showFilterOperator: false,
        field: "address",
        header: _ctx.$t('customer.address'),
        sortable: true
      }, {
        filter: _withCtx(({ filterModel, filterCallback }) => [
          _createVNode(_component_InputText, {
            type: "text",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            onKeyup: ($event: any) => (filterCallback()),
            class: "p-column-filter",
            placeholder: _ctx.$t('customer.filterByAddress')
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup", "placeholder"])
        ]),
        filterclear: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            icon: "pi pi-times",
            onClick: ($event: any) => (filterCallback()),
            class: "p-button-warning p-button-rounded"
          }, null, 8, ["onClick"])
        ]),
        filterapply: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            icon: "pi pi-search",
            class: "p-button-success p-button-rounded",
            onClick: ($event: any) => (_ctx.submitSearch('name', filterCallback))
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    _: 1
  }, 8, ["value", "rows", "selection"]))
}