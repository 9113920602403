import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  autocomplete: "off",
  class: "p-fluid"
}
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { for: "discountPercentage" }
const _hoisted_4 = { class: "field" }
const _hoisted_5 = { for: "discountAmount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Button, {
      disabled: !!_ctx.disabled,
      onClick: _ctx.toggle,
      class: "p-button-secondary",
      icon: "pi pi-percentage"
    }, null, 8, ["disabled", "onClick"]),
    _createVNode(_component_OverlayPanel, {
      ref: "discountOp",
      appendTo: "body",
      style: {"width":"250px"},
      breakpoints: { '960px': '75vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("ticket.discountPercentage")), 1),
            _createVNode(_component_InputNumber, {
              "model-value": _ctx.copyPercentage,
              class: "p-inputtext-sm",
              id: "discountPercentage",
              "max-fraction-digits": 20,
              "allow-empty": true,
              min: 0,
              max: 100,
              showButtons: "",
              format: false,
              buttonLayout: "horizontal",
              incrementButtonIcon: "pi pi-plus",
              decrementButtonIcon: "pi pi-minus",
              mode: "decimal",
              style: {"height":"30px"},
              suffix: "%",
              "input-class": "text-right",
              onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPercentChange(Number($event.value)))),
              onKeyup: [
                _cache[1] || (_cache[1] = ($event: any) => (_ctx.onPercentChangeKeyup($event.target.value))),
                _cache[2] || (_cache[2] = _withKeys(($event: any) => ((_ctx.$refs['discountOp'] as any).hide()), ["enter"]))
              ]
            }, null, 8, ["model-value"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("ticket.discountAmount")), 1),
            _createVNode(_component_InputNumber, {
              "model-value": _ctx.copyAmount,
              class: "p-inputtext-sm",
              id: "discountAmount",
              "max-fraction-digits": 20,
              "allow-empty": true,
              min: 0,
              max: _ctx.total,
              showButtons: "",
              format: false,
              buttonLayout: "horizontal",
              incrementButtonIcon: "pi pi-plus",
              decrementButtonIcon: "pi pi-minus",
              mode: "decimal",
              style: {"height":"30px"},
              "input-class": "text-right",
              onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onAmountChange(Number($event.value)))),
              onKeyup: [
                _cache[4] || (_cache[4] = ($event: any) => (_ctx.onAmountChangeKeyup($event.target.value))),
                _cache[5] || (_cache[5] = _withKeys(($event: any) => ((_ctx.$refs['discountOp'] as any).hide()), ["enter"]))
              ]
            }, null, 8, ["model-value", "max"])
          ]),
          _createVNode(_component_Button, {
            onClick: _ctx.validateDiscount,
            class: "p-mt-2 p-button-outlined",
            label: _ctx.$t('validate')
          }, null, 8, ["onClick", "label"])
        ])
      ]),
      _: 1
    }, 512)
  ], 64))
}