
import { defineComponent, ref, watch } from "vue";
import { extractNumber } from "@/graphql/utils/utils";
export default defineComponent({
  name: "POSDiscount",
  props: [
    "total",
    "percent",
    "amount",
    "ticketAmount",
    "ticketPercentage",
    "disabled",
    "ticketId",
    "fromRecover",
  ],
  emits: ["update:percent", "update:amount"],
  setup(props, { emit }) {
    const copyAmount = ref(0);
    const copyPercentage = ref(0);
    const discountOp = ref<any>(null);
    function toggle(event: any) {
      if (props.total > 0) {
        copyAmount.value = props.amount;
        copyPercentage.value = props.percent;
        discountOp.value.toggle(event);
      }
    }
    function validateDiscount() {
      emit("update:amount", copyAmount.value);
      emit("update:percent", copyPercentage.value);
      if (discountOp.value) discountOp.value.hide();
    }
    watch(
      () => [props.ticketId, props.total],
      (v: any[]) => {
        if (!props.fromRecover) {
          if (v[0]) {
            copyAmount.value = props.ticketAmount || 0;
            copyPercentage.value = props.ticketPercentage || 0;
          } else onPercentChange(0);
        }
        validateDiscount();
      }
    );
    function onPercentChange(value: number) {
      if (value <= 100) {
        copyPercentage.value = value;
        copyAmount.value = (props.total * value) / 100;
      }
    }
    function onAmountChange(value: number) {
      if (value < props.total) {
        copyAmount.value = value;
        copyPercentage.value = (value * 100) / props.total;
      }
    }
    //for mobile browser
    function onPercentChangeKeyup(value: string) {
      const val = extractNumber(value);
      if (copyPercentage.value != val) onPercentChange(val);
    }

    function onAmountChangeKeyup(value: string) {
      const val = extractNumber(value);
      if (copyAmount.value != val) onAmountChange(val);
    }
    return {
      copyAmount,
      copyPercentage,
      onPercentChange,
      onAmountChange,
      validateDiscount,
      discountOp,
      toggle,
      onPercentChangeKeyup,
      onAmountChangeKeyup,
    };
  },
});
