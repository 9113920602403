import { MutationRemoveTicketArgs, TicketRemoveOutput } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { TICKET_FIELDS } from "@/graphql/ticket/ticket";
import { STOCK_FIELDS } from "@/graphql/stock/stock";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { ref } from "vue";
import { activeActivity } from "@/plugins/i18n";
import { MOVEMENT_FIELDS } from "@/graphql/movement/movement";
import { CUSTOMER_FIELDS } from "@/graphql/customer/customer.sdl";
import { PAYMENT_RECEIPT_FIELDS } from "@/graphql/payment-receipt/payment-receipt";
import {
  addPosTicket,
  removePosTicket,
} from "@/graphql/sequence/pos.ticket.cache";
import { NOT_STORABLE_FIELDS } from "@/graphql/product/not-storable";

type RemoveTicketData = {
  removeTicket: TicketRemoveOutput;
};

const REMOVE_TICKET = gql`
    mutation RemoveTicket($input: TicketRemoveInput!) {
        removeTicket(input: $input) {
            tickets {
              ${TICKET_FIELDS}
              payments {
                ${PAYMENT_RECEIPT_FIELDS}
              }
              customer {
                ${CUSTOMER_FIELDS}
              }
              movements {
                ${MOVEMENT_FIELDS}
                stock {
                  id
                  quantity
                  inclTax
                }
                product {
                  id
                  name
                  changeable
                  notStorable {
                    ${NOT_STORABLE_FIELDS}
                  }
                }
              }
            }
            stocks {
                ${STOCK_FIELDS}
            }
        }
    }
`;

export const useRemoveTicket = (callback: (isModification) => void) => {
  const toast = useToast();
  const { t } = useI18n();
  const confirm = useConfirm();
  const isModification = ref(false);
  let ticketId = "";
  const {
    loading: removeTicketLoading,
    mutate,
    onDone,
  } = useMutation<RemoveTicketData, MutationRemoveTicketArgs>(REMOVE_TICKET, {
    update: (cache, { data }) => {
      const tickets = data?.removeTicket?.tickets;
      if (tickets?.length) addPosTicket(cache, tickets[0]);
      else removePosTicket(cache, ticketId);
    },
  });

  onDone(({ data }) => {
    const ticket = data?.removeTicket?.tickets;
    if (!ticket) {
      toast.add({
        severity: "warn",
        summary: t("delete.title"),
        detail: t("ticket.remove.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else {
      if (!isModification.value && ticket?.length) {
        if (ticket.length === 2) {
          toast.add({
            severity: "info",
            summary: t(`ticket.remove.title${ticket[1].status}`),
            detail: t(`ticket.remove.success${ticket[1].status}`),
            life: parseInt(process.env.VUE_APP_TOAST_LIFE),
          });
        } else {
          toast.add({
            severity: "info",
            summary: t(`ticket.remove.title2`),
            detail: t(`ticket.remove.success2`),
            life: parseInt(process.env.VUE_APP_TOAST_LIFE),
          });
        }
      }
      callback(isModification.value);
    }
  });

  async function removeTicket(id: string, sequenceId: string) {
    isModification.value = false;
    ticketId = id;
    confirm.require({
      header: t("delete.title"),
      message: t("ticket.warningRemove"),
      group: "dialog",
      accept: () => {
        void mutate({
          input: {
            id,
            activityId: activeActivity.value.id,
            sequenceId,
          },
        });
      },
      icon: "pi pi-exclamation-triangle",
      rejectLabel: t("no"),
      acceptLabel: t("yes"),
      acceptClass: "p-button-warning",
    });
  }

  function modifyTicket(id: string, sequenceId: string) {
    isModification.value = true;
    ticketId = id;
    void mutate({
      input: {
        id,
        activityId: activeActivity.value.id,
        sequenceId,
      },
    });
  }
  return { removeTicketLoading, removeTicket, modifyTicket, isModification };
};
