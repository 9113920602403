
import {
  computed,
  defineComponent,
  defineAsyncComponent,
  reactive,
  ref,
  watch,
  nextTick,
} from "vue";

import PosFindClient from "./PosFindCustomer.vue";
import { TicketRow, useCreateTicket } from "@/graphql/ticket/create.ticket";
import { activeActivity } from "@/plugins/i18n";
import { extractNumber, receivedSum, totalInt } from "@/graphql/utils/utils";
import { useUpdateTicket } from "@/graphql/ticket/update.ticket";
import POSDiscount from "./POSDiscount.vue";
import { usePrintV2 } from "@/graphql/ticket/ticket.printer";
import { useUpdateMovementQuantity } from "@/graphql/movement/update.movement.quantity";
import { useTicketRecover } from "@/graphql/ticket/ticket.recover";
import { getActiveTerminal } from "@/graphql/sequence/active.sequence";
import { useI18n } from "vue-i18n";
import ticket from "@/i18n/fr-FR/ticket";
import { TicketStatusEnum, getDiscountValues } from "@/graphql/ticket/ticket";
import { productParams } from "@/graphql/utils/product";

export default defineComponent({
  name: "Ticket",
  components: {
    PaymentReceipt: defineAsyncComponent(
      () => import("@/components/payment/PaymentReceipt.vue")
    ),
    PosFindClient,
    POSDiscount,
    PrintSetupDialog: defineAsyncComponent(
      () => import("./PrintSetupDialog.vue")
    ),
  },
  props: [
    "ticketRows",
    "sequenceId",
    "ticket",
    "isModification",
    "printBarcode",
    "freeTerminal",
  ],
  emits: [
    "remove-row",
    "update:ticket",
    "update:ticketRows",
    "update:isModification",
    "modify",
    "recovered",
  ],
  setup(props, { emit }) {
    const {
      paymentDialog,
      printBuild,
      setupTicket,
      ticketAfterPay,
      customer,
      printAfterPay,
      btnPrinterEnable,
    } = usePrintV2(props.printBarcode);
    const { updateQuantity } = useUpdateMovementQuantity();
    const { recoverLoading, submitRecover, recover } = useTicketRecover(() => {
      emit("recovered");
    });

    let lastRemoveId = 0;
    function onEdit(slotProps: any, quantity: number) {
      const index = slotProps.index;
      if (props.ticket.length) {
        const id = props.ticket[0].movements[index].id;
        updateQuantity({ id, quantity });
      } else if (!quantity) {
        if (slotProps.data.id === lastRemoveId) return;
        lastRemoveId = slotProps.data.id;
        emit("remove-row", index);
      }
    }

    function onPriceEdit(index: number, price: string) {
      const tic: TicketRow[] = props.ticketRows.map((t) => t);
      /***Price is negative if product is disscount */
      const { priceSign } = productParams({ name: tic[index].name });
      const newPrice = extractNumber(price);
      tic[index].price = priceSign * Math.abs(newPrice);
      tic[index].changeable = false;
      emit("update:ticketRows", tic);
    }
    function resetTicket() {
      emit("update:ticket", []);
      emit("update:ticketRows", []);
      emit("update:isModification", false);
      ticketAfterPay.amount = 0;
      ticketAfterPay.percentage = 0;
      customer.value = null;
    }
    const { tm } = useI18n();
    /**PRINT BEGINING**/
    const printSetupDialog = reactive({
      show: false,
      option: 1,
    });
    const printerOverlay = ref<boolean>(false);
    const printerSettings = [...getActiveTerminal().printerSettings];
    // print before cashing, if disabled, option 0 is not found
    const cantPrintBeforeCash = printerSettings.findIndex((x) => x === 0) < 0;
    const disablePrinter = computed(
      () =>
        (cantPrintBeforeCash && !props.ticket[0]?.id) || // Don't print before save
        !props.ticketRows.length || // No thing to print
        btnPrinterEnable.value > 0
    );
    // make normal printer always available
    if (cantPrintBeforeCash) printerSettings.unshift(0);
    // short printer options
    printerSettings.sort((a: number, b: number) => a - b);
    function onClickPrinterOption(option: number) {
      printSetupDialog.option = option;
      printerOverlay.value = false;
      //Don't need to open dialog ticket setup
      if ([0, 1, 4].includes(option))
        printBuild(props.ticket[0] || props.ticketRows, option);
      //Need to open dialog ticket setup
      else {
        setupTicket(props.ticket[0] || props.ticketRows);
        printSetupDialog.show = true;
      }
    }
    const printOptions = printerSettings.map((index) => ({
      label: tm("terminal.printerOptions")[index],
      icon: "pi pi-print",
      style: `padding-top: 15px;`,
      command: () => onClickPrinterOption(index),
    }));
    function onClickPrint() {
      // immediatly print
      if (printerSettings.length <= 1) {
        onClickPrinterOption(printerSettings[0] || 0);
      } else printerOverlay.value = true;
    }
    /**END PRINT SETTINGS**/

    const resume = computed(() => {
      const total = totalInt(props.ticketRows);
      const ticket = props.ticket[0];
      return {
        receivedSum: receivedSum(ticket?.payments),
        total,
        tAfterPay: total - ticketAfterPay.amount,
        recover: ticket?.status === 2 && !recover.value,
        disabled: ticket && ticket?.status !== 2,
        editableQuantity:
          !props.ticket.length || (ticket.status === 2 && recover.value),
      };
    });

    const dicountSettings = computed(() => {
      return getDiscountValues(props.ticket[0], props.ticketRows);
    });

    watch(
      () => [...props.ticketRows],
      (tic) => {
        if (!tic.length) customer.value = null;
        else {
          nextTick(() => {
            const lastIndex = tic.length - 1;
            if (tic[lastIndex].changeable) {
              const input = document.getElementById(`price${lastIndex}`);
              input.focus();
            }
          });
        }
      }
    );

    watch(
      () => props.ticket,
      () => (recover.value = false)
    );

    // UPDATE TICKET
    const { updateTicketLoading, submitUpdateTicket } = useUpdateTicket(
      (ticket: any) => {
        paymentDialog.value = false;
        if (printAfterPay.value) printBuild(ticket, printSetupDialog.option);
        recover.value = false;
        setTimeout(() => resetTicket(), 1000);
      }
    );
    // CREATE TICKET
    const { creationLoading, submitCreation, cStatus } = useCreateTicket(
      (ticket: any) => {
        paymentDialog.value = false;
        if (ticket) {
          if (ticket && ticket.status !== 2 && printAfterPay.value) {
            printSetupDialog.option = 0;
            printBuild(ticket, printSetupDialog.option);
          }
          setTimeout(() => resetTicket(), 1000);
        }
      }
    );
    // DISABLE PAYMENT BUTTON
    const disablePayment = computed(() => {
      return (
        !props.freeTerminal ||
        updateTicketLoading.value ||
        creationLoading.value ||
        recoverLoading.value ||
        !resume.value.total ||
        [
          TicketStatusEnum.PAYED,
          TicketStatusEnum.CREDIT_PAYED,
          TicketStatusEnum.CANCELLED,
          TicketStatusEnum.RETURN,
          TicketStatusEnum.OFFER,
        ].includes(props.ticket[0]?.status) ||
        (props.ticket[0]?.status === 2 && !recover.value)
      );
    });

    const disablePause = computed(() => {
      return (
        !props.freeTerminal ||
        updateTicketLoading.value ||
        creationLoading.value ||
        !props.ticketRows.length ||
        (props.ticket[0] && props.ticket[0].status !== 2)
      );
    });

    //ON TICKET SUBMIT
    function onSubmitTicket(event: any = { status: 2, payment: null }) {
      const { amount, percentage } = ticketAfterPay;
      if (!props.ticket.length) {
        submitCreation({
          customerId: customer.value?.id,
          ...event,
          amount,
          percentage,
          sequenceId: props.sequenceId,
          activityId: activeActivity.value.id,
          ticketLines: props.ticketRows.map((row: TicketRow) => ({
            productId: row.productId,
            stockId: row.stockId,
            quantity: row.quantity,
            price: row.price,
          })),
        });
      } else {
        submitUpdateTicket({
          id: props.ticket[0].id,
          customerId: customer.value?.id,
          ...event,
          amount,
          percentage,
          activityId: activeActivity.value.id,
          sequenceId: props.sequenceId,
        });
      }
    }

    function onKeyPress(event: any) {
      if ([",", "."].includes(event.key)) {
        event.preventDefault();
        let val = event.target.value;
        if (String(val).includes(".")) return;
        // Get the current cursor position
        const cursorPos = event.target.selectionStart;
        // Modify the input value by inserting a period at the cursor position
        event.target.value =
          val.slice(0, cursorPos) + "." + val.slice(cursorPos);
        // Move the cursor position after the inserted period
        event.target.selectionStart = cursorPos + 1;
        event.target.selectionEnd = cursorPos + 1;
      }
    }

    return {
      onKeyPress,
      onPriceEdit,
      extractNumber,
      paymentDialog,
      print,
      ticketAfterPay,
      printAfterPay,
      onEdit,
      /**Recover ticket*/
      recover,
      recoverLoading,
      submitRecover,
      resume,
      /**Update ticket*/
      creationLoading,
      submitCreation,
      cStatus,
      /**update ticket*/
      updateTicketLoading,
      submitUpdateTicket,
      /**validate ticket*/
      onSubmitTicket,
      // others
      activeActivity,
      customer,
      /**printer*/
      printSetupDialog,
      printerOverlay,
      onClickPrint,
      printOptions,
      printBuild,
      /** BUTTONS STATES **/
      btnPrinterEnable,
      disablePrinter,
      disablePayment,
      disablePause,
      dicountSettings,
    };
  },
});
