
import { computed, defineComponent, reactive, ref } from "vue";
import {
  MutationTerminalConnectArgs,
  QueryActiveSequenceArgs,
  QueryProductsArgs,
  Terminal,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import {
  ACTIVE_SEQUENCE,
  ActiveSequenceData,
  getActiveTerminal,
} from "@/graphql/sequence/active.sequence";
import { onBeforeRouteLeave } from "vue-router";
import { CONSTANTS } from "@/graphql/utils/utils";
import { connexionLost } from "@/graphql/utils/connexion-lost-dialog";
import { activeActivity } from "@/plugins/i18n";
import { PRODUCTS, ProductsData } from "@/graphql/product/products";

type Data = {
  terminalConnect: Terminal;
};
const MUTATION = gql`
  mutation TerminalConnexion($input: TerminalConnectedInput!) {
    terminalConnect(input: $input) {
      id
      activityId
      lastUse
      userId
    }
  }
`;

export default defineComponent({
  name: "TerminalGuard",
  props: {
    currentUser: Number,
  },
  setup(props) {
    const RETRY = 15;
    const { mutate, onDone } = useMutation<Data, MutationTerminalConnectArgs>(
      MUTATION
    );

    useQuery<ProductsData, QueryProductsArgs>(
      PRODUCTS,
      {
        activityId: activeActivity.value.id,
      },
      { pollInterval: 20 * 1000, fetchPolicy: "cache-and-network" }
    );

    const activeTerminal = reactive<Terminal>(getActiveTerminal());

    const { refetch: updateActiveSequence } = useQuery<
      ActiveSequenceData,
      QueryActiveSequenceArgs
    >(ACTIVE_SEQUENCE, {
      terminalId: activeTerminal.id,
    });

    const firstLoading = ref(true);
    const connexion = ref(true);
    const stayInReadonlyMode = ref(false);
    //A user is currently connected, but not me
    const readonlyMode = activeTerminal.userId !== props.currentUser;

    function disconnect() {
      connexion.value = false;
      if (activeTerminal.userId) {
        void mutate({
          input: {
            id: activeTerminal.id,
            connect: false,
            timeout: RETRY,
            autoConnexion: false,
            readonlyMode: false,
          },
        });
      }
    }

    function connect(autoConnexion = true) {
      void mutate({
        input: {
          id: activeTerminal.id,
          connect: true,
          timeout: RETRY,
          readonlyMode,
          autoConnexion,
        },
      });
    }

    //Connect to terminal
    connect(false);

    const connectTimer = setInterval(() => {
      connexion.value = true;
      connect();
    }, RETRY * 1000);

    let sequenceTimer = null;

    onBeforeRouteLeave(() => {
      clearInterval(connectTimer);
      clearInterval(sequenceTimer);
      disconnect();
      //window.onbeforeunload = undefined;
    });

    onDone(({ data }) => {
      if (data?.terminalConnect) {
        Object.assign(activeTerminal, data.terminalConnect);
        localStorage.setItem(
          CONSTANTS.activeTerminal,
          JSON.stringify(activeTerminal)
        );

        if (data.terminalConnect.userId !== props.currentUser) {
          if (firstLoading.value) {
            sequenceTimer = setInterval(() => {
              updateActiveSequence();
            }, RETRY * 1000);
          }
        }
        firstLoading.value = false;
      }
    });

    const freeTerminal = computed(() => {
      return (
        activeTerminal.userId == props.currentUser && !stayInReadonlyMode.value
      );
    });

    function activeStayInReadOnlyMode() {
      stayInReadonlyMode.value = true;
      clearInterval(connectTimer);
      disconnect();
    }

    const activePrompts = computed(
      () =>
        !firstLoading.value &&
        connexion.value &&
        !connexionLost.value &&
        !stayInReadonlyMode.value
    );

    return {
      connect,
      activeTerminal,
      freeTerminal,
      firstLoading,
      connexion,
      connexionLost,
      stayInReadonlyMode,
      activeStayInReadOnlyMode,
      activePrompts,
      maximized: ref(true),
    };
  },
});
