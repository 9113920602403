import { gql } from "@apollo/client/core";
import {
  CreateTicketInput,
  CreateTicketOutput,
  Movement,
  MutationCreateTicketArgs,
  Ticket,
} from "../types";
import { TICKET_FIELDS } from "@/graphql/ticket/ticket";
import { ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { MOVEMENT_FIELDS } from "@/graphql/movement/movement";
import { PAYMENT_RECEIPT_FIELDS } from "@/graphql/payment-receipt/payment-receipt";
import { CUSTOMER_FIELDS } from "@/graphql/customer/customer.sdl";
import { addPosTicket } from "@/graphql/sequence/pos.ticket.cache";
import { NOT_STORABLE_FIELDS } from "@/graphql/product/not-storable";

type CreateTicketData = {
  createTicket: CreateTicketOutput;
};

export type TicketRow = {
  index?: number;
  name: string;
  vat: number;
  price: number;
  changeable?: boolean;
  productId?: number;
  stockId?: string;
  quantity: number;
  maybeChange?: boolean;
  reference?: string;
  id?: number;
};

const CREATE_TICKET = gql`
    mutation CreateTicket($input: CreateTicketInput!){
        createTicket(input: $input) {
          ticket {
              ${TICKET_FIELDS}
              payments {
                ${PAYMENT_RECEIPT_FIELDS}
              }
              customer {
                ${CUSTOMER_FIELDS}
              }
              movements {
                ${MOVEMENT_FIELDS}
                stock {
                  id
                  quantity
                  inclTax
                }
                product {
                  id
                  name
                  changeable
                  reference
                  notStorable {${NOT_STORABLE_FIELDS}}
                }
              }
            }
          stocks {
            id
            quantity
            inclTax
          }
        }
    }
`;

export const useTicketTable = () => {
  const ticketRows = ref<TicketRow[]>([]);
  function addToTicketRow(row: TicketRow) {
    ticketRows.value.push({
      ...row,
      index: ticketRows.value.length,
      id: new Date().getTime(),
    });
    setTimeout(() => {
      const query = document.querySelector(".p-datatable-wrapper");
      query.scrollTo({ behavior: "smooth", top: query.scrollHeight });
    }, 2);
  }
  function removeTicketRow(index: number) {
    ticketRows.value.splice(index, 1);
  }
  function getChangeable(m: Movement): boolean {
    let changeable = m.product.changeable == 1;
    if (!changeable) {
      if (m.product.notStorable) {
        changeable = m.product.notStorable.inclTax == 0;
      } else if (m.stock) {
        changeable = m.stock.inclTax == 0;
      }
    }
    changeable = !changeable ? null : false; // close
    return changeable;
  }
  function onTicketSelect(movements: Movement[]) {
    ticketRows.value = movements.map((m, index) => {
      return {
        name: m.product.name,
        productId: m.product.id,
        reference: m.product.reference,
        changeable: null,
        maybeChange: getChangeable(m),
        stockId: m.stockId || "",
        vat: m.vat,
        price: m.price,
        quantity: m.quantity,
        index,
        id: new Date().getTime(),
      };
    });
  }
  function onModifyOrRecover() {
    ticketRows.value.forEach((row) => {
      row.changeable = row.maybeChange;
    });
  }
  return {
    ticketRows,
    addToTicketRow,
    removeTicketRow,
    onTicketSelect,
    onModifyOrRecover,
  };
};

export const useCreateTicket = (callback: (ticket: Ticket) => void) => {
  const toast = useToast();
  const { t } = useI18n();
  const cStatus = ref(0);
  const {
    loading: creationLoading,
    mutate,
    onDone,
  } = useMutation<CreateTicketData, MutationCreateTicketArgs>(CREATE_TICKET, {
    update(cache, { data }) {
      if (data?.createTicket) addPosTicket(cache, data.createTicket.ticket);
    },
  });
  onDone(({ data }) => {
    const ticket = data?.createTicket?.ticket;
    toast.add({
      severity: ticket ? "success" : "warn",
      summary: t(`ticket.create.title${cStatus.value}`),
      detail: t(
        `ticket.${ticket ? "create.success" + cStatus.value : "failed"}`
      ),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    callback(ticket);
  });
  function submitCreation(input: CreateTicketInput) {
    cStatus.value = input.status;
    void mutate({ input });
  }
  return { creationLoading, submitCreation, cStatus };
};
