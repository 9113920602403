import {
  Movement,
  MutationUpdateMovementQuantityArgs,
  UpdateQuantityInput,
} from "@/graphql/types";
import { gql } from "@apollo/client";
import { MOVEMENT_FIELDS } from "./movement";
import { STOCK_FIELDS } from "../stock/stock";
import { useMutation } from "@vue/apollo-composable";

type UpdateMovementQuantityData = {
  updateMovementQuantity: Movement;
};

const UPDATE_MOVEMENT_QUANTITY = gql`
    mutation UpdateMovementQuantity($input: UpdateQuantityInput!) {
        updateMovementQuantity(input: $input){
            ${MOVEMENT_FIELDS}
            stock{${STOCK_FIELDS}}
        }
    }
`;

export const useUpdateMovementQuantity = () => {
  const { loading: loadingQuantity, mutate } = useMutation<
    UpdateMovementQuantityData,
    MutationUpdateMovementQuantityArgs
  >(UPDATE_MOVEMENT_QUANTITY);
  function updateQuantity(input: UpdateQuantityInput) {
    void mutate({ input });
  }
  return {
    loadingQuantity,
    updateQuantity,
  };
};
