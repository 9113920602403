
import { defineComponent, ref } from "vue";
import { usePaginateCustomers } from "@/graphql/customer/paginate.customers";
import { Customer } from "@/graphql/types";
import PosCustomersTable from "@/components/pos/PosCustomersTable.vue";

export default defineComponent({
  name: "FindClient",
  props: ["modelValue", "currentCustomer", "disabled", "fromRecover"],
  emits: ["update:modelValue"],
  components: { PosCustomersTable },
  setup() {
    return {
      autoFilteredValues: ref([]),
      ...usePaginateCustomers(5),
      visible: ref(false),
    };
  },
  methods: {
    filter(event: any) {
      if (!event.query.trim().length) {
        this.autoFilteredValues = [...this.customers.items];
      } else {
        const filter = event.query.toLowerCase();
        this.autoFilteredValues = this.customers.items.filter((customer) => {
          return customer.name.toLowerCase().startsWith(filter);
        });
        if (!this.autoFilteredValues.length) {
          this.filters.value.global.value = filter;
          this.submitSearch();
        }
      }
    },
  },
  watch: {
    currentCustomer: {
      handler: function (val: Customer) {
        if (!this.fromRecover) {
          this.autoFilteredValues.unshift(val);
          this.$emit("update:modelValue", val);
        }
      },
    },
  },
});
