import { gql } from "@apollo/client";
import { useMutation } from "@vue/apollo-composable";
import { MutationTicketRecoverArgs, Stock } from "@/graphql/types";
import { removePosTicket } from "@/graphql/sequence/pos.ticket.cache";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { ref } from "vue";

type TicketRecoverData = {
  ticketRecover: Stock[];
};

const QUERY = gql`
  mutation TicketRecover($input: TicketRecoverInput!) {
    ticketRecover(input: $input) {
      id
      quantity
    }
  }
`;

export const useTicketRecover = (callback: () => void) => {
  const toast = useToast();
  const { t } = useI18n();
  const recover = ref(false);
  let idToRemove = "";
  const {
    mutate,
    onDone,
    loading: recoverLoading,
  } = useMutation<TicketRecoverData, MutationTicketRecoverArgs>(QUERY, {
    update: (cache, { data }) => {
      if (data?.ticketRecover) removePosTicket(cache, idToRemove);
    },
  });
  onDone(({ data }) => {
    if (!data?.ticketRecover) {
      toast.add({
        severity: "warn",
        summary: t("ticket.recover.title"),
        detail: t("ticket.recover.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else callback();
  });
  function submitRecover(id: string) {
    idToRemove = id;
    recover.value = true;
    void mutate({ input: { id } });
  }
  return { recoverLoading, submitRecover, recover };
};
