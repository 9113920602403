import { ApolloCache } from "@apollo/client/core";
import { QueryActiveSequenceArgs, Ticket } from "@/graphql/types";
import {
  ACTIVE_SEQUENCE,
  ActiveSequenceData,
  getActiveTerminal,
} from "@/graphql/sequence/active.sequence";

import { cloneDeep } from "@apollo/client/utilities";

export const addPosTicket = (cache: ApolloCache<any>, ticket: Ticket) => {
  const terminalId = getActiveTerminal().id;
  const sequence = cache.readQuery<ActiveSequenceData, QueryActiveSequenceArgs>(
    {
      query: ACTIVE_SEQUENCE,
      variables: { terminalId },
    }
  )?.activeSequence;
  if (sequence) {
    const { tickets, ...res } = cloneDeep(sequence);
    tickets.unshift(ticket);
    cache.writeQuery<ActiveSequenceData, QueryActiveSequenceArgs>({
      data: { activeSequence: { ...res, tickets } },
      query: ACTIVE_SEQUENCE,
      variables: { terminalId },
    });
  }
};

export const removePosTicket = (cache: ApolloCache<any>, id: string) => {
  const terminalId = getActiveTerminal().id;
  const sequence = cache.readQuery<ActiveSequenceData, QueryActiveSequenceArgs>(
    {
      query: ACTIVE_SEQUENCE,
      variables: { terminalId },
    }
  )?.activeSequence;
  if (sequence) {
    const { tickets, ...res } = cloneDeep(sequence);
    const index = tickets.findIndex((t) => t.id === id);
    tickets.splice(index, 1);
    cache.writeQuery<ActiveSequenceData, QueryActiveSequenceArgs>({
      data: { activeSequence: { ...res, tickets } },
      query: ACTIVE_SEQUENCE,
      variables: { terminalId },
    });
  }
};
