import {
  UpdateTicketInput,
  MutationUpdateTicketArgs,
  Ticket,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { TICKET_FIELDS } from "@/graphql/ticket/ticket";
import { PAYMENT_RECEIPT_FIELDS } from "@/graphql/payment-receipt/payment-receipt";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { CUSTOMER_FIELDS } from "@/graphql/customer/customer.sdl";
import { MOVEMENT_FIELDS } from "@/graphql/movement/movement";
import { ref } from "vue";
import { NOT_STORABLE_FIELDS } from "@/graphql/product/not-storable";

type UpdateTicketData = {
  updateTicket: Ticket;
};
const UPDATE_TICKET = gql`
    mutation UpdateTicket($input: UpdateTicketInput!){
        updateTicket(input: $input){
            ${TICKET_FIELDS}
            customer {
                ${CUSTOMER_FIELDS}
            }
            payments {
                ${PAYMENT_RECEIPT_FIELDS}
            }
            movements {
                ${MOVEMENT_FIELDS}
                product {
                    id
                    name
                    changeable
                    notStorable {
                        ${NOT_STORABLE_FIELDS}
                    }
                }
            }
        }
    }
 `;

export const useUpdateTicket = (callback: (ticket: Ticket) => void) => {
  const toast = useToast();
  const { t } = useI18n();
  const uStatus = ref(0);
  const {
    loading: updateTicketLoading,
    mutate,
    onDone,
  } = useMutation<UpdateTicketData, MutationUpdateTicketArgs>(UPDATE_TICKET);
  onDone(({ data }) => {
    const ticket = data?.updateTicket;
    toast.add({
      severity: ticket ? "success" : "warn",
      summary: t(`ticket.update.title${uStatus.value}`),
      detail: t(
        `ticket.${ticket ? "update.success" + uStatus.value : "failed"}`
      ),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    if (ticket) callback(data.updateTicket);
  });
  function submitUpdateTicket(input: UpdateTicketInput) {
    uStatus.value = input.status;
    void mutate({ input });
  }
  return { updateTicketLoading, submitUpdateTicket };
};
