
import { defineComponent, PropType } from "vue";
import { Customer } from "@/graphql/types";
import { usePaginateCustomers } from "@/graphql/customer/paginate.customers";
import CreateCustomer from "@/components/customer/CustomerCreate.vue";

export default defineComponent({
  name: "PosCustomersTable",
  components: { CreateCustomer },
  props: {
    selectionMode: {
      type: String,
      default: "single",
    },
    modelValue: Object as PropType<Customer>,
  },
  setup() {
    const { loading, customers, input, submitSearch, filters, clearFilter } =
      usePaginateCustomers(10);
    return {
      loading,
      customers,
      input,
      submitSearch,
      filters,
      clearFilter,
    };
  },
});
